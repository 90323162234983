@import url(http://fonts.googleapis.com/css?family=Roboto:100,300,400,500,700,900);
@import url(http://fonts.googleapis.com/css?family=Source+Sans+Pro:200,300,400,600,700,900);

@import (inline) "reset.css";
//@import "clearfix.less";

html 
{
	font-size: 62.5%;
	.font2();
}

.debug
{
	background-color: white;
	background-image: linear-gradient(transparent 50%, rgba(255,0,0,0.1) 50%);
	//background-size: 1em 0.75em;
	background-size: 1rem 0.75rem;
	background-position: top center;

	.col-prediction > :nth-child(even),
	.col-branch > :nth-child(odd),
	{
		background-color: hsla(120, 100%, 50%, 0.2);
	}

	.col-prediction > :nth-child(odd),
	.col-branch > :nth-child(even),
	{
		background-color: hsla(240, 100%, 50%, 0.2);
	}

	p {
		background-color: rgba(128,128,128,0.1);
	}
}

body
{
	background: transparent;
	font-size: 1.6rem;
	line-height: 2.4rem;
	padding-left: 0.6rem;
	.w4();
}

a
{
	text-decoration: none;
	color: inherit;
}

//body { font-size: 3em }
.font1 { font-family: "Helvetica Neue" }
.font3 { font-family: "Roboto" }
.font2 { font-family: "Source Sans Pro", sans-serif; }
.w1 { font-weight: 100 } //Lightest
.w2 { font-weight: 200 }
.w3 { font-weight: 300 } //Light
.w4 { font-weight: 400 } //Normal
.w5 { font-weight: 600 } //Bold
.w6 { font-weight: 600 }
.w7 { font-weight: 700 } //Boldest
.w8 { font-weight: 800 }
.w9 { font-weight: 900 }

.l03 { line-height: 1.2rem }
.l05 { line-height: 1.8rem }
.l1 { line-height: 2.4rem }
.l2 { line-height: 3.0rem }
.l3 { line-height: 3.6rem }

.f05 { font-size: 1.2rem }
.f1 { font-size: 1.6rem }
.f2 { font-size: 1.8rem }
.f3 { font-size: 2.1rem }
.f4 { font-size: 2.4rem }
.f5 { font-size: 3.0rem }

@grey:		#777;
@blue:		#428bca;
@green:		#5cb85c;
@sky:		#5bc0de;
@warning:	#f0ad4e;
@danger:	#d9534f;

@grid-unit: 0.6rem;
@row-padding: @grid-unit * 4;

h1
{
	.f4();
	.w5();
	padding-top: @row-padding;
	padding-bottom: @row-padding + @grid-unit * 2;
}

ul.prediction-table 
{
	display: table;
	
	li 
	{
		display: table-row;
	}
}

.col-prediction, .col-branch
{
	display: table-cell;
	padding-bottom: @row-padding;
}

.col-prediction
{
	text-align: center;
	color: @green;

	.key {
		//.f1();
		//text-transform: uppercase;
		font-variant: small-caps;
	}
}

#spacer
{
	visibility: hidden;
	//max-height: 0.1rem;
	//overflow: hidden;
	//padding: 0;
}

.col-branch
{
	padding-left: @grid-unit * 2;

	.qualifier
	{
		color: @blue;

		span
		{
			white-space: nowrap;
		}
	}

	.letter, .key
	{
		color: @grey;
	}

	.letter
	{
		text-transform: lowercase;
		font-variant: small-caps;
	}
}

.qualifier
{
	.f05();
	.l03();
	.w5();
	text-transform: uppercase;
	display: block;
}

.prediction,
.name
{
	.f3();
	.l05();
	.w5();
	display: block;
	padding: 0.6rem 0;
}



.prediction.soon { .w7(); }
.prediction.later { .w3(); }

.destination {
	.l05();
	display: block;
	padding: 0.3rem 0;
}

.express
{
	color: @green;
}

.blue-night
{
	color: @sky;
}

.short-turn,
{
	color: @danger;
}

.extra-fare,
{
	color: @danger;
}

ul.stop-list
{
	li
	{
		padding-bottom: @row-padding;
	}
	
	a
	{
		color: @blue;
		.f2();
		.l05();
		.w4();
	}
	
	.distance 
	{
		color: @grey;
		.f05();
		.l03();
		.w5();
	}
}


//9, 10, 11, 12, 14, 16, 18, 21, 24, 30, 48